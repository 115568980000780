import { TrackedButton } from '@automata/ui';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { RouteKey } from 'GlobalRoutes';
import { useRoute } from 'hooks/useRoute';
import { useFlag } from 'hooks/unleashHooks';

export const menuItems: MainLinkProps[] = [
  {
    label: 'Workflows',
    page: 'workflow',
  },
  {
    label: 'Hubs',
    page: 'operator',
  },
  {
    label: 'Workcells',
    page: 'workcells',
  },
];

export interface MainLinkProps {
  label: string;
  page: RouteKey;
}

const MainLink = ({ label, page }: MainLinkProps): JSX.Element | null => {
  const route = useRoute(page);

  return (
    <MuiLink href={route} component={NextLink}>
      <TrackedButton
        trackLabel={`AppBar.mainNav.navigate${page}`}
        color="neutral"
        variant="text"
      >
        {label}
      </TrackedButton>
    </MuiLink>
  );
};

export const MainLinks = (): JSX.Element => {
  const workcellsEnabled = useFlag('fe.cue_workcell');
  const links = workcellsEnabled
    ? menuItems.filter((item) => item.page !== 'operator')
    : menuItems.filter((item) => item.page !== 'workcells');

  return (
    <Stack direction="row" alignItems="center" ml={3} gap={2}>
      {links.map(({ label, page }) => (
        <MainLink key={page} label={label} page={page} />
      ))}
    </Stack>
  );
};
